import { Trans } from "@lingui/react/macro"
import { List, Text, ThemeIcon, Title } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import { PageWrapper } from "./_components"
import { WebsiteLink } from "./_WebsiteLink"

export const Page = ({ currentPath }: { currentPath: string }) => (
  <PageWrapper currentPath={currentPath}>
    <PageContents />
  </PageWrapper>
)

export const PageContents = () => (
  <>
    <Title order={2} ta="center" lh="3rem" fz="3rem" fw={600} mb="md">
      <Trans>You deserve better.</Trans>
    </Title>
    <Text ta="center" fz="xl" fw={500} mb="3rem">
      <Trans>This year, file your own return with Canadian-made tax software</Trans>
    </Text>
    <Text mb="md">
      <Trans>We've got you covered:</Trans>
    </Text>
    <FeatureList />
  </>
)

const FeatureList = () => (
  <List
    spacing="sm"
    mb="xl"
    center
    icon={
      <ThemeIcon variant="white" color="green">
        <IconCheck />
      </ThemeIcon>
    }
  >
    <List.Item>
      <Trans>CRA NETFILE Certified</Trans>
    </List.Item>
    <List.Item>
      <Trans>Get your maximum refund in as little as two weeks</Trans>
    </List.Item>
    <List.Item>
      <Trans>
        Easy to use, accurate, and{" "}
        <WebsiteLink href="/pricing" fw={600}>
          pay what you want
        </WebsiteLink>
      </Trans>
    </List.Item>
    <List.Item>
      <Trans>No ads, no distractions, no nonsense</Trans>
    </List.Item>
    <List.Item>
      <Trans>
        Your data is kept{" "}
        <WebsiteLink href="/privacy" fw={600}>
          private
        </WebsiteLink>
      </Trans>
    </List.Item>
    <List.Item>
      <Trans>
        Friendly email support from{" "}
        <WebsiteLink href="/about" fw={600}>
          the people who made the product
        </WebsiteLink>
      </Trans>
    </List.Item>
  </List>
)
